<template>
  <div>
    <CRow>
      <CCol col="12" xl="12">
        <CCard>
          <CCardBody>
            <CRow>
              <CCol class="pills-wrapper">
                <b-nav pills>
                  <b-nav-item
                    @click="selectStatus('All')"
                    :active="status === 'All'"
                    >All</b-nav-item
                  >
                  <b-nav-item
                    @click="selectStatus('processing')"
                    :active="status === 'processing'"
                    >Processing</b-nav-item
                  >
                  <b-nav-item
                    @click="selectStatus('started')"
                    :active="status === 'started'"
                    >Started</b-nav-item
                  >
                  <b-nav-item
                    @click="selectStatus('completed')"
                    :active="status === 'completed'"
                    >Completed</b-nav-item
                  >
                </b-nav>
              </CCol>
              <CCol>
                <CInput
                  :placeholder="$t('ORDERS.SEARCH')"
                  v-model="orderFilters.search"
                  @input="getOrderDetails"
                  class="px-2 float-right col-sm-6"
                />

                <CButton
                  @click="filterModal = true"
                  size="sm"
                  color="lightborder"
                  class="px-2 float-right"
                >
                  <CIcon name="cil-filter" />&nbsp;{{ $t("COMMON.FILTERS") }}
                </CButton>
                <vue-json-to-csv
                  v-if="orders_export !== null"
                  :json-data="orders_export"
                  :labels="getCSVLables()"
                  :csv-title="getCSVName('orders')"
                  class="px-2 float-right"
                >
                  <CButton
                    @click="getOrderDetailsForExport"
                    size="sm"
                    color="lightborder"
                  >
                    <CIcon name="cil-cloud-download" />&nbsp;{{
                      $t("COMMON.EXPORT")
                    }}
                  </CButton>
                </vue-json-to-csv>
              </CCol>
            </CRow>
            <CDataTable
              hover
              :items="orders"
              :fields="fields"
              @row-clicked="(item, index) => goTo('/orders/', item['id'])"
            >
              <template #status="data">
                <td>
                  <CBadge :color="getOrderBadge(data.item.status)"
                    >{{ getCapitalized(data.item.status) }}
                  </CBadge>
                </td>
              </template>
              <template #payment_option="data">
                <td>
                  {{ getStatusCapitalized(data.item.payment_option) }}
                </td>
              </template>
              <template #is_live="data">
                <td>
                  <div>{{ getType(data.item.is_live) }}</div>
                </td>
              </template>
              <template #amount="data">
                <td>
                  <div>{{ formatAmount(data.item.amount) }}</div>
                </td>
              </template>

              <template #created_at="data">
                <td>
                  <div>
                    {{ formatDate(data.item.created_at) }}
                  </div>
                </td>
              </template>
            </CDataTable>
            <div class="col-md-11">
              <b-pagination
                size="md"
                align="center"
                :total-rows="totalRows"
                @change="pageChange"
                v-model="pageNumber"
                :per-page="orderFilters.limit"
              ></b-pagination>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Select filters to apply"
      color="light"
      :no-close-on-backdrop="false"
      :centered="true"
      :show.sync="filterModal"
    >
      <CContainer>
        <CRow class="mb-3">
          <label class="col-12 col-xl-12">{{
            $t("ORDERS.FILTER_BY.DATE")
          }}</label>
          <date-picker
            class="primary col-12 col-xl-12"
            v-model="dates"
            type="date"
            range
            placeholder="Select date range"
            @change="selectRange"
          ></date-picker>
        </CRow>
        <CRow class="mb-3">
          <label class="col-12 col-xl-12">{{
            $t("ORDERS.FILTER_BY.CURRENCY")
          }}</label>
          <multiselect
            v-model="value2"
            :options="options2"
            :multiple="false"
            class="mx-3"
            :placeholder="$t('ORDERS.FILTERS.CURRENCY')"
            track-by="name"
            label="name"
            @select="selectFilter2"
            @remove="clearFilter2"
          ></multiselect>
        </CRow>
        <CRow>
          <label class="col-12 col-xl-12">{{
            $t("ORDERS.FILTER_BY.MODE")
          }}</label>
          <multiselect
            v-model="value3"
            :options="options3"
            :multiple="false"
            class="mx-3"
            :placeholder="$t('ORDERS.FILTERS.MODE')"
            track-by="name"
            label="name"
            @select="selectFilter3"
            @remove="clearFilter3"
          ></multiselect>
        </CRow>
      </CContainer>
      <template #footer>
        <CButton @click="resetFilter">{{
          $t("COMMON.RESET")
        }}</CButton>
        <CButton @click="filterModal = false" color="durianprimary">{{
          $t("COMMON.DONE")
        }}</CButton>
      </template>
    </CModal>
    <CModal title="Modal title" color="secondary" :show.sync="exportModal">
      <vue-json-to-csv :json-data="orders">
        <CButton color="durianprimary">
          <CIcon name="cil-cloud-download" />&nbsp;Download CSV
        </CButton>
      </vue-json-to-csv>
    </CModal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { listOrder } from "@/api/order.api.js";
import VueJsonToCsv from "vue-json-to-csv";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { sandboxEventEmitter } from "@/../config.js";

const COMPONENT_NAME = "Orders";
export default {
  components: {
    Multiselect,
    VueJsonToCsv,
    DatePicker,
  },
  name: COMPONENT_NAME,
  data() {
    return {
      filterModal: false,
      exportModal: false,
      visible: false,
      selected: null,
      totalRows: 0,
      fields: [
        {
          key: "id",
          label: this.$t("ORDERS.TABLE.3"),
          _classes: "font-weight-bold",
        },
        {
          key: "amount",
          label: this.$t("ORDERS.TABLE.2"),
          _classes: "font-family-aperchu",
        },
        { key: "currency", label: "Currency", _classes: "font-family-aperchu" },
        {
          key: "email",
          label: this.$t("ORDERS.TABLE.12"),
          _classes: "font-family-aperchu",
        },
        {
          key: "payment_option",
          label: this.$t("ORDERS.TABLE.15"),
          _classes: "font-family-aperchu",
        },
        {
          key: "created_at",
          label: this.$t("ORDERS.TABLE.4"),
          _classes: "font-family-aperchu",
        },
        { key: "status", label: "Status", _classes: "font-family-aperchu" },
      ],
      orderFilters: {
        skip: 0,
        limit: 50,
        filters: [],
        from: "",
        to: "",
        search: "",
        payment_links: false,
      },
      options1: [
        { name: "paid", column: "status" },
        { name: "started", column: "status" },
        { name: "partial", column: "status" },
        { name: "processed", column: "status" },
        { name: "completed", column: "status" },
      ],
      options2: [
        { name: "IDR", column: "currency" },
        { name: "USD", column: "currency" },
      ],
      options3: [
        { name: "Live", column: "is_live" },
        { name: "Sandbox", column: "is_live" },
      ],
      value1: null,
      value2: null,
      value3: null,
      status: "All",
      orders: [],
      pageNumber: 1,
      value: "",
      valueto: "",
      today: "",
      before: "",
      CSVLables: {
        id: {},
        created_at: {},
        order_ref_id: {},
        currency: {},
        amount: {},
        status: {},
        payment_date: {},
        payment_details_type: {},
        customer_id: {},
        given_name: {},
        email: {},
      },
      orders_export: [],
      range: {
        start: new Date(2020, 0, 1),
        end: new Date(),
      },
      dates: [],
    };
  },
  methods: {
    selectRange() {
      if (this.ifValid(this.dates[0]) && this.ifValid(this.dates[1])) {
        this.orderFilters.from = this.moment(this.dates[0])
          .format("YYYY-MM-DD")
          .toString();
        this.orderFilters.to = this.moment(this.dates[1])
          .format("YYYY-MM-DD")
          .toString();
      } else {
        this.orderFilters.from = this.before;
        this.orderFilters.to = this.today;
      }
      this.getOrdersByDate();
    },
    resetFilter() {
      this.dates = [];
      this.value2 = null;
      this.value3 = null;
      this.orderFilters.filters = this.orderFilters.filters.filter((val) => {
        return !["currency", "is_live"].includes(val.key);
      });
      this.initializeData();
    },
    onContext(ctx) {
      if (ctx.selectedDate != null) {
        let date = this.moment(ctx.selectedDate).format("MM-DD-YYYY");
        this.orderFilters.from = date.toString();
      } else {
        this.orderFilters.from = "";
      }
      if (this.filterModal) {
        this.getOrdersByDate();
      }
    },
    onContextTo(ctx) {
      if (ctx.selectedDate != null) {
        let date = this.moment(ctx.selectedDate).format("MM-DD-YYYY");
        this.orderFilters.to = date.toString();
      } else {
        this.orderFilters.to = "";
      }
      if (this.filterModal) {
        this.getOrdersByDate();
      }
    },
    getOrdersByDate() {
      if (
        (this.orderFilters.from != "" && this.orderFilters.to != "") ||
        (this.orderFilters.from == "" && this.orderFilters.to == "")
      ) {
        this.getOrderDetails();
      }
    },
    pageChange(val) {
      this.orderFilters.skip = (val - 1) * this.orderFilters.limit;
      this.getOrderDetails();
    },
    removeFromOrderFilter(val) {
      let removeIndex = -1;
      this.orderFilters.filters.find((v, i) => {
        if (v.key == val) {
          removeIndex = i;
        }
      });
      if (removeIndex != -1) {
        this.orderFilters.filters.splice(removeIndex, 1);
      }
    },
    selectStatus(val) {
      this.status = val;
      let filter = {};
      filter.column = "status";
      filter.name = val;
      if (val != "All") {
        this.selectFilter1(filter);
      } else {
        this.clearFilter1(filter);
      }
    },
    selectFilter1(val) {
      this.removeFromOrderFilter(val.column);
      let f = {};
      f.value = val.name;
      f.key = val.column;
      this.orderFilters.filters.push(f);
      this.orderFilters.skip = 0;
      this.getOrderDetails();
    },
    clearFilter1(val) {
      this.value1 = null;
      let removeElementAtIndex = -1;
      this.orderFilters.filters.forEach(function (v, index) {
        if (v.key === val.column) {
          removeElementAtIndex = index;
        }
      });
      if (removeElementAtIndex !== -1) {
        this.orderFilters.filters.splice(removeElementAtIndex, 1);
      }
      this.getOrderDetails();
    },
    selectFilter2(val) {
      this.removeFromOrderFilter(val.column);
      let f = {};
      f.value = val.name;
      f.key = val.column;
      this.orderFilters.filters.push(f);
      this.orderFilters.skip = 0;
      this.getOrderDetails();
    },
    clearFilter2(val) {
      this.value2 = null;
      let removeElementAtIndex = -1;
      this.orderFilters.filters.forEach(function (v, index) {
        if (v.value === val.name) {
          removeElementAtIndex = index;
        }
      });
      if (removeElementAtIndex !== -1) {
        this.orderFilters.filters.splice(removeElementAtIndex, 1);
      }
      this.getOrderDetails();
    },
    selectFilter3(val) {
      this.removeFromOrderFilter(val.column);
      let f = {};
      if (val.name == "Live") {
        f.value = "true";
      } else {
        f.value = "false";
      }
      f.key = val.column;
      this.orderFilters.filters.push(f);
      this.orderFilters.skip = 0;
      this.getOrderDetails();
    },
    clearFilter3(val) {
      this.value3 = null;
      let removeElementAtIndex = -1;
      this.orderFilters.filters.forEach(function (v, index) {
        if (v.key === val.column) {
          removeElementAtIndex = index;
        }
      });
      if (removeElementAtIndex !== -1) {
        this.orderFilters.filters.splice(removeElementAtIndex, 1);
      }
      this.getOrderDetails();
    },
    getOrderDetails() {
      const {
        skip,
        limit,
        from,
        to,
        search,
        payment_links,
        filters,
      } = this.orderFilters;
      let paramsMap = new Map();
      paramsMap["skip"] = skip;
      paramsMap["limit"] = limit;
      paramsMap["from"] = from || this.before;
      paramsMap["to"] = to || this.today;
      paramsMap["search"] = search;
      paramsMap["payment_links"] = payment_links;
      paramsMap["filters"] = JSON.stringify(filters);

      listOrder(this.generatePostParams(paramsMap))
        .then((response) => {
          const err = this.verifyHttpResponse(response);
          if (err === false) {
            this.orders = response.data.data.orders;
            this.totalRows = response.data.data.count;
          }
        })
        .catch(this.handleAPIError);
    },
    getCSVLables() {
      this.CSVLables.created_at.title = this.$t("ORDERS.CSV.DATE");
      this.CSVLables.id.title =
        this.$t("COMMON.APPNAME") + this.$t("ORDERS.TABLE.3");
      this.CSVLables.order_ref_id.title = this.$t("ORDERS.TABLE.6");
      this.CSVLables.currency.title = this.$t("ORDERS.TABLE.7");
      this.CSVLables.amount.title = this.$t("ORDERS.TABLE.2");
      this.CSVLables.status.title = this.$t("ORDERS.TABLE.1");
      this.CSVLables.payment_date.title = this.$t("ORDERS.CSV.PAYDATE");
      this.CSVLables.payment_details_type.title = this.$t("ORDERS.CSV.PAYTYPE");
      this.CSVLables.customer_id.title = this.$t("ORDERS.TABLE.5");
      this.CSVLables.given_name.title = this.$t("ORDERS.CSV.NAME");
      this.CSVLables.email.title = this.$t("ORDERS.CSV.EMAIL");
      return this.CSVLables;
    },
    getOrderDetailsForExport() {
      this.initialiseExportData();
    },
    initialiseExportData() {
      let paramsMap = new Map();
      paramsMap["skip"] = 0;
      paramsMap["limit"] = this.totalRows;
      paramsMap["from"] = this.before;
      paramsMap["to"] = this.today;
      paramsMap["search"] = "";
      paramsMap["payment_links"] = this.orderFilters.payment_links;
      paramsMap["filters"] = JSON.stringify(this.orderFilters.filters);

      listOrder(this.generatePostParams(paramsMap))
        .then((response) => {
          const err = this.verifyHttpResponse(response);
          if (err === false) {
            this.orders_export = response.data.data.orders;
          }
        })
        .then(() => {
          this.cleanCSVData();
        })
        .catch(this.handleAPIError);
    },
    cleanCSVData() {
      if (this.orders_export.length > 0) {
        this.orders_export = JSON.parse(JSON.stringify(this.orders_export));
        for (let i = 0; i < this.orders_export.length; i++) {
          this.orders_export[i].created_at = this.formatDate(
            this.orders_export[i].created_at
          );
          if (this.orders_export[i].payment_date != undefined) {
            this.orders_export[i].payment_date = this.formatDate(
              this.orders_export[i].payment_date
            );
          } else {
            this.orders_export[i].payment_date = "";
          }
          if (this.orders_export[i].payment_details_type != undefined) {
            this.orders_export[i].payment_details_type = this.getPayType(
              this.orders_export[i].payment_details_type
            );
          } else {
            this.orders_export[i].payment_details_type = "";
          }
        }
      }
    },
    async initializeData() {
      const dates = await this.initializeDates();
      this.today = dates.today;
      this.before = dates.before;
      this.orderFilters.to = this.today;
      this.orderFilters.from = this.before;
      await this.getOrderDetails();
    },
  },
  mounted() {
    this.initializeData();
  },
  created() {
    this.$eventHub.$on(sandboxEventEmitter.prefix + COMPONENT_NAME, this.initializeData);
  },
  beforeDestroy(){
    this.$eventHub.$off(sandboxEventEmitter.prefix + COMPONENT_NAME);
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css">
.form-control {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: white;
  background: #418af8;
  height: 32px;
  text-decoration: none;
}

.nav-link {
  text-decoration: none;
}
</style>
