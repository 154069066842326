import { httpClient } from './httpClient.js';

const
    LISTORDERS = 'dashboard/orders',
    GETORDERDETAILS = 'dashboard/orders/';

const listOrder = (listParams) => httpClient.dashboardService.get(LISTORDERS, { "params": listParams});
const getOrder = (id, expandParams) => httpClient.dashboardService.get(GETORDERDETAILS + id, { "params": expandParams});

export {
    listOrder,
    getOrder
};
